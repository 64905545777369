import React from 'react'
import { graphql } from "gatsby"
import { Container } from 'react-grid-system'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from '../components/rows/hero'
import Intro from '../components/rows/intro'
import Outro from '../components/rows/outro'
import ImgSection from '../components/rows/imgsection'
import DataAccordion from '../components/elements/accordion'

import Banner from '../images/img/hero/eco-impact-hero.jpg'

export default function EcoImpact({data}) { // <== Make sure data is sent to the function

    return (
        <Layout>
        <Seo title="Our Eco-Impact" />
        <Hero
            title="Our Eco-Impact"
            banner={Banner}
        />
        {data.allStrapiEcoImpact.edges.map(({ node }) => ( // <== Map out the data from the node.
            <> 
            <div className="intro py-56">
                <Intro key={node.eco_intro.id}
                    title={node.eco_intro.title}
                    subtitle={node.eco_intro.subtitle}
                    description={node.eco_intro.description}
                />
                <DataAccordion 
                  data={node.eco_accordion}
                />
            </div>
                <ImgSection key={node.eco_img.id}
                    buttonwhite
                    subtitle={node.eco_img.subtitle}
                    title={node.eco_img.title}
                    description={node.eco_img.description}      
                    image={node.eco_img.image.localFile.publicURL}
                    link={node.eco_img.button_title}
                    href={node.eco_img.button_link}
                />
                
                <Outro key={node.eco_outro.id}
                    subtitle={node.eco_outro.subtitle}
                    title={node.eco_outro.title}
                    link={node.eco_outro.button_title}
                    href={node.eco_outro.button_link}
                />
            </>
        ))}            
        </Layout>    
    )
}


export const query = graphql`
query EcoImpact {
    allStrapiEcoImpact {
      edges {
        node {
          eco_intro {
            id
            subtitle
            title
            description
          }
          eco_accordion {
            id
            title
            description
          }
          eco_img {
            id
            subtitle
            title
            description
            button_title
            button_link
            image {
              localFile {
                publicURL
              }
            }
          }
          eco_outro {
            id
            subtitle
            title
            button_title
            button_link
          }
        }
      }
    }
  }
`